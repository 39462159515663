import Image from 'next/image';

import contact from '@/consts/contact';
import cn from '@/utils/cn';

type ContactData = {
  icon: string;
  alt: string;
  text: string;
  value: string;
};

const constactsData = [
  { icon: '/phone.svg', alt: 'phone', hrefType: 'tel', text: 'Zadzwoń', value: contact.formattedPhone },
  { icon: '/mail.svg', alt: 'mail', hrefType: 'mailto', text: 'Napisz', value: contact.email },
  { icon: '/map-pin.svg', alt: 'map-pin', text: 'Odwiedź nas', value: contact.address },
];

const ContactData = () => (
  <div className="flex flex-col justify-between gap-y-6 desktop:flex-row">
    {constactsData.map((data) => {
      const Component = data.hrefType ? 'a' : 'div';

      return (
        <div key={data.icon} className="flex w-[264px] flex-col items-center">
          <Image src={data.icon} alt={data.alt} width={40} height={40} />
          <div className="mb-1 mt-5 text-body1 text-pink">{data.text}</div>
          <Component
            className={cn(
              `whitespace-pre-line text-center font-arsenal text-subtitle1`,
              data.hrefType ? 'hover:underline' : undefined
            )}
            href={data.hrefType ? `${data.hrefType}:${data.value.replace(/\s+/g, '')}` : undefined}
          >
            {data.value}
          </Component>
        </div>
      );
    })}
  </div>
);

export default ContactData;
