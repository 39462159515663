type ScheduleWeek =
  | {
      type: 'oneTime';
      title: string;
      week?: never;
      providers: string[];
      topics: string[];
    }
  | {
      type: 'weekly';
      title: string;
      week: number;
      providers: string[];
      topics: string[];
    };

type Schedule = Record<'oneTime' | 'weekly', ScheduleWeek[]>;

const commonProviders = ['Anna Lis', 'Beata Jacheć'];

const schedules: Schedule = {
  oneTime: [
    {
      type: 'oneTime',
      title: 'Kurs obejmuje skróconą tematykę poniższych zagadnień',
      providers: commonProviders,
      topics: [
        'Rozpoczęcie porodu',
        'Mechanizm porodu',
        'Pozycje wertykalne',
        'Oddech podczas porodu',
        'Karmienie piersią',
        'Pielęgnacja noworodka oraz kąpiel',
      ],
    },
  ],
  weekly: [
    {
      type: 'weekly',
      title: 'Ciąża',
      week: 1,
      providers: [...commonProviders, 'Patrycja Niejadlik'],
      topics: [
        'Przebieg ciąży prawidłowej',
        'Dieta',
        'Przygotowanie do porodu',
        'Kiedy jechać do szpitala',
        'Organizacja przestrzeni w domu, torby do porodu',
        'Relaksacja w ciąży',
      ],
    },
    {
      type: 'weekly',
      title: 'Poród',
      week: 2,
      providers: commonProviders,
      topics: [
        'Przebieg porodu prawidłowego',
        'Ból porodowy + metody łagodzenia',
        'Oddychanie',
        'Metody relaksacji i znaczenie ruchu',
        'Sprzęty pomocne podczas porodu',
        'Pozycje porodowe',
      ],
    },
    {
      type: 'weekly',
      title: 'Połóg',
      week: 3,
      providers: commonProviders,
      topics: [
        'Fizjologia połogu',
        'Nieprawidłowości w połogu',
        'Powrót seksualności po porodzie',
        'Cięcie cesarskie',
        'Polski Bank Komórek Macierzystych',
      ],
    },
    {
      type: 'weekly',
      title: 'Karmienie piersią',
      week: 4,
      providers: commonProviders,
      topics: [
        'Fizjologia laktacji',
        'Korzyści płynące z karmienia piersią',
        'Stany niepokojące',
        'Żywienie alternatywne noworodka',
        'Pozycje podczas karmienia piersią',
      ],
    },
    {
      type: 'weekly',
      title: 'Noworodek',
      week: 5,
      providers: commonProviders,
      topics: [
        'Rozwój noworodka w pierwszych tygodniach życia',
        'Badania u noworodka',
        'Szczepienia',
        'Stany przejściowe u noworodka',
        'Pielęgnacja noworodka (teoria)',
        'Kąpiel noworodka (teoria)',
      ],
    },
    {
      type: 'weekly',
      title: 'Noworodek - zajęcia z fizjoterapeutą dziecięcym',
      week: 6,
      providers: [...commonProviders, 'Monika Więcław-Borecka'],
      topics: ['Pielęgnacja noworodka (praktyka)', 'Kąpiel noworodka (praktyka)'],
    },
    {
      type: 'weekly',
      title: 'Zajęcia z fizjoterapeutą uroginekologicznym',
      week: 7,
      providers: ['Anna Arndt'],
      topics: [],
    },
    {
      type: 'weekly',
      title: 'Psychologiczny aspekt ciąży i porodu. Pierwsza pomoc noworodka. Zakończenie i podsumowanie zajęć.',
      week: 8,
      providers: [],
      topics: [],
    },
  ],
};

export default schedules;
