type AnchorId = 'aboutUs' | 'oursGoals' | 'employees' | 'courses' | 'gallery' | 'faq' | 'download' | 'contact';

const anchors: Record<AnchorId, { id: string; name: string }> = {
  aboutUs: {
    id: 'poznajNas',
    name: 'Poznaj nas',
  },
  oursGoals: {
    id: 'naszeCele',
    name: 'Nasze Cele',
  },
  employees: {
    id: 'nasiInstruktorzy',
    name: 'Nasi instruktorzy',
  },
  courses: {
    id: 'zajecia',
    name: 'Zajęcia',
  },
  gallery: {
    id: 'galeria',
    name: 'Galeria',
  },
  faq: {
    id: 'faq',
    name: 'Pytania i Odpowiedzi',
  },
  download: {
    id: 'download',
    name: 'Do pobrania',
  },
  contact: {
    id: 'kontakt',
    name: 'Kontakt',
  },
};

export default anchors;
